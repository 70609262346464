import React from "reactn";
import { withRouter } from "react-router-dom";
import { config } from "../../config";
import { SelectField } from "../shared/SelectField";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { supportEmail, supportHeader, toAdminPortalJobPosting } from "../../helpers/supportHelper";
import { Urls } from "../../helpers/enums";

class Navigation extends React.PureComponent {
  mobileThreshold = 990;

  state = {
    open: false,
    prevUrlLocation: this.props.location.pathname,
    isMobile: window.innerWidth <= this.mobileThreshold,
    activeSubmenu: null, // Track the currently active submenu
  };

  componentDidMount() {
    this.listenOnScroll();
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ prevUrlLocation: this.props.location.pathname });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => {});
    window.removeEventListener("resize", this.handleResize);
  }

  openNavBar = () => {
    this.navigationBarRef.style.display = this.state.open ? "none" : "block";
    this.setState({ open: !this.state.open });
  };

  isTabActive = (selectedTab) => {
    const { prevUrlLocation } = this.state;

    if (selectedTab === "/") {
      // Special case for Home: Match exact URL
      return prevUrlLocation === "/" ? "has-submenu active" : "has-submenu";
    } else {
      // Match routes that start with the selectedTab
      return prevUrlLocation.startsWith(selectedTab) ? "has-submenu active" : "has-submenu";
    }
  };

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
  };

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= this.mobileThreshold });
  };

  toggleSubmenu = (submenu) => {
    this.setState((prevState) => ({
      activeSubmenu: prevState.activeSubmenu === submenu ? null : submenu,
    }));
  };

  render() {
    let LANGUAGES = [
      { value: "en", label: i18next.t("language.EN") },
      { value: "si", label: i18next.t("language.SI") },
    ];

    const { t } = this.props;
    const isOpen = this.state.open;
    const { isMobile, activeSubmenu } = this.state;

    return (
      <header
        id="topnav"
        className={
          this.global.activeNav
            ? "defaultscroll scroll-active active scroll"
            : "defaultscroll scroll-active active"
        }
      >
        <div className="tagline">
          <div className="container">
            <div className="float-left">
              <div className="email">
                <a href={supportHeader}>
                  <i className="mdi mdi-email"></i> {supportEmail}
                </a>
              </div>
            </div>
            <div className="float-right">
              <ul className="topbar-list list-unstyled d-flex" style={{ margin: "11px 0px" }}>
                <li className="list-inline-item">
                  <a>
                    <SelectField
                      value={this.global.language}
                      option={LANGUAGES}
                      placeholder={t("language.LANGUAGES")}
                      onSelectChange={(val) => this.changeLanguage(val)}
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="container">
          {/* Show div below only when on mobile devices */}
          {isMobile ? (
            <div className="d-flex justify-content-between align-items-center">
              <a href={Urls.Home} className="logo">
                <img
                  src="./talent-home-light.png"
                  alt="Applicant Tracking System"
                  className="logo-light"
                  height="38"
                />
                <img
                  src="./talent-home-black.png"
                  alt="Applicant Tracking System"
                  className="logo-dark"
                  height="38"
                />
              </a>
              <div className="menu-extras" onClick={this.openNavBar}>
                <div className="menu-item">
                  <a className={isOpen ? "navbar-toggle open" : "navbar-toggle"}>
                    <div className="lines">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-between align-items-center">
              <a href={Urls.Home} className="logo">
                <img
                  src="./talent-home-light.png"
                  alt="Applicant Tracking System"
                  className="logo-light"
                  height="38"
                />
                <img
                  src="./talent-home-black.png"
                  alt="Applicant Tracking System"
                  className="logo-dark"
                  height="38"
                />
              </a>
              <a
                href={config.platformUrl}
                onClick={(e) => toAdminPortalJobPosting(e, i18next)}
                className="btn btn-custom btn-sm"
              >
                <i className="mdi mdi-cloud-upload"></i> {t("job.POST_A_JOB")}
              </a>
            </div>
          )}

          <div className="d-flex justify-content-center">
            <div
              id="navigation"
              className="navigation-menu"
              ref={(ref) => (this.navigationBarRef = ref)}
            >
              <ul className="navigation-menu dropdown-navigation">
                <li className={this.isTabActive(Urls.Home)}>
                  <a href={Urls.Home}>{t("common.HOME")}</a>
                </li>
                <li className={this.isTabActive(Urls.AboutUs)}>
                  <a href={Urls.AboutUs}>{t("common.ABOUT_US")}</a>
                </li>
                <li className={this.isTabActive(Urls.BlogList)}>
                  <a href={Urls.BlogList}>{t("blog.BLOG")}</a>
                </li>
                <li className={this.isTabActive(Urls.Features)}>
                  <a href={Urls.Features}>{t("common.FEATURES")}</a>
                </li>
                <li className={this.isTabActive(Urls.Pricing)}>
                  <a href={Urls.Pricing}>{t("pricing.PRICING")}</a>
                </li>
                <li className={this.isTabActive(Urls.Faq)}>
                  <a href={Urls.Faq}>{t("common.FAQ")}</a>
                </li>
                {/* Submenu with toggle */}
                {/* Add back later, when more is known about how to present a company on the portal. */}
                {/* <li className="has-submenu">
                  <a href="#" onClick={() => this.toggleSubmenu("employers")}>
                    {t("common.EMPLOYERS")}
                  </a>
                  <span className="menu-arrow"></span>
                  <ul className={`submenu ${activeSubmenu === "employers" ? "open" : ""}`}>
                    <li>
                      <a href={Urls.CompanyDetailsSample}>{t("company.COMPANY_DETAILS_SAMPLE")}</a>
                    </li>
                    <li>
                      <a href={Urls.Team}>{t("common.TEAM")}</a>
                    </li>
                  </ul>
                </li> */}

                <li className={this.isTabActive(Urls.JobList)}>
                  <a href="#" onClick={() => this.toggleSubmenu("jobs")}>
                    {t("job.JOBS")}
                  </a>
                  <span className="menu-arrow"></span>
                  <ul className={`submenu ${activeSubmenu === "jobs" ? "open" : ""}`}>
                    <li>
                      <a href={Urls.JobList}>{t("job.JOB_LIST")}</a>
                    </li>
                  </ul>
                </li>

                <li className="has-submenu">
                  <a href={supportHeader}>{t("common.CONTACT")}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }

  listenOnScroll = () => {
    window.addEventListener("scroll", () => {
      if (window.scrollY === 0) {
        this.dispatch.changeClassOnScroll({
          ...this.global,
          activeNav: false,
        });
      } else if (!this.global.activeNav) {
        this.dispatch.changeClassOnScroll({
          ...this.global,
          activeNav: true,
        });
      }
    });
  };
}

export default withRouter(withTranslation("translations")(Navigation));
