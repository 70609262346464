import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { setGlobal } from "reactn";
import * as store from "./store/global.store";
import Navigation from "./components/navigation/Navigation";
import FooterAlt from "./components/shared/FooterAlt";
import Footer from "./components/shared/Footer";
import { Urls } from "./helpers/enums";
import { useTranslation } from "react-i18next";
import { config } from "./config";

setGlobal(store.initialGlobalState());

// Components that are loaded on-demand using React's lazy loading
const Home = lazy(() => import("./components/home/Home"));
const JobList = lazy(() => import("./components/job/JobList"));
const JobDetails = lazy(() => import("./components/jobDetails/JobDetails"));
const CompanyDetails = lazy(() => import("./components/company/CompanyDetails"));
const Faq = lazy(() => import("./components/help/Faq"));
const Pricing = lazy(() => import("./components/pricing/Pricing"));
const Features = lazy(() => import("./components/help/Features"));
const Team = lazy(() => import("./components/company/Team"));
const CompanyDetailsSample = lazy(() => import("./components/company/CompanyDetailsSample"));
const AboutUs = lazy(() => import("./components/aboutUs/AboutUs"));
const RouterWrapper = lazy(() => import("./components/shared/RouterWrapper"));
const BlogList = lazy(() => import("./components/blog/BlogList"));
const BlogDetails = lazy(() => import("./components/blog/BlogDetails"));

// A component for handling external routes by redirecting to external URLs
function ExternalRoute({ computeUrl }) {
  useEffect(() => {
    window.location.href = computeUrl();
  }, [computeUrl]);
  return null;
}

// The main App component
function App() {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [i18nInitialized, setI18nInitialized] = useState(false);

  // Effect hook for internationalization initialization
  useEffect(() => {
    i18n.on("initialized", () => setI18nInitialized(true));
    return () => i18n.off("initialized");
  }, [i18n]);

  // Effect hook for handling language changes
  useEffect(() => {
    const handleLanguageChange = (lng) => setCurrentLanguage(lng);
    i18n.on("languageChanged", handleLanguageChange);
    return () => i18n.off("languageChanged", handleLanguageChange);
  }, [i18n]);

  // Render loading state if i18n is not initialized
  if (!i18nInitialized) {
    return <div>Loading translations...</div>;
  }

  // Main application JSX with Router and lazy-loaded components
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <RouterWrapper>
            <Navigation />
            <Switch>
              {/* SEO Improvement: Use 'exact' prop for precise matching */}
              <Route exact path={Urls.Home} component={Home} />
              <Route exact path={Urls.JobList} component={JobList} />
              <Route exact path={Urls.JobDetailsPreview} component={JobDetails} />
              <Route exact path={Urls.CompanyDetailsSample} component={CompanyDetailsSample} />
              <Route exact path={Urls.JobDetails} component={JobDetails} />
              <Route exact path={Urls.CompanyDetails} component={CompanyDetails} />
              <Route exact path={Urls.Faq} component={Faq} />
              <Route exact path={Urls.Pricing} component={Pricing} />
              <Route exact path={Urls.Features} component={Features} />
              <Route exact path={Urls.Team} component={Team} />
              <Route exact path={Urls.AboutUs} component={AboutUs} />
              <Route exact path={Urls.BlogList} component={BlogList} />
              <Route exact path={`${Urls.BlogList}/:slug`} component={BlogDetails} />
              {/* External routes handled separately */}
              <ExternalRoute
                path="/admin-portal/job/post"
                computeUrl={() => `${config.platformUrl}/jobs/0`}
              />
              <ExternalRoute
                path="/admin-portal/applicationCreation"
                computeUrl={(a) =>
                  `${config.platformUrl}/applicationCreation/${a.history.location.state?.id}`
                }
              />
              <ExternalRoute
                path="/admin-portal/findJobWizard"
                computeUrl={() =>
                  `${config.platformUrl}/findJobWizard?origin=FromWizardJobPortal&language=${currentLanguage}`
                }
              />
              <Redirect to={Urls.Home} />
            </Switch>
            <Footer />
            <FooterAlt />
          </RouterWrapper>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;

export function disableOnLoading(status) {
  const elements = document.getElementsByClassName("disableOnLoad");
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.opacity = status ? 0.7 : 1;
    elements[i].style.pointerEvents = status ? "none" : "all";
  }
}
